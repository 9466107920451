import service from '@/service'
/**
 *
 * @param {{product:string,supplierName:string}} params
 * @description 获取平均价
 */
export function getAveragePurchasePrice(params) {
  return service['api-scm']({
    url: '/public/purchase/save/getAveragePurchasePrice',
    method: 'get',
    params
  })
}
/**
 * @param {{product:string,supplierName:string}} params
 * @description 获取含税采购价
 */
export function getRecommendPrice(params) {
  return service['api-scm']({
    url: '/public/purchase/getRecommendPrice',
    method: 'get',
    params
  })
}
/**
 * @param {string} codeList
 */
export function getLogisticItemsByCodes(codeList) {
  return service['api-scm']({
    url: '/public/logistics/getCodeAndNameMapByCodeList',
    method: 'get',
    params: { codeList }
  })
}

/**
 * 获取所有的省市区地址
 * @param {*} params
 */
export function getAllGeographicCoordinates(params) {
  return service['api-scm']({
    url: '/public/order/getAllGeographicCoordinates',
    method: 'get',
    params
  })
}

/**
 * @param {{rejectReqQOList:Array<{id:string,taskId:string,assignee?:string,remarks?:string}>,rejectTypeEnum:string}} data
 * @description 批量驳回
 */
export function activitiReject(data) {
  return service['api-scm']({
    url: '/public/activiti/batchReject',
    method: 'post',
    data
  })
}

/**
 * @description 查询订单信息 代办悬浮框用
 */
export function findOrderAgentListByCode(params) {
  return service['api-scm']({
    url: '/public/order/findOrderAgentListByCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 检测是否有人正在编辑详情页
 * @param {{documentType: string, lockCode: string}} data
 * @returns {{code: 200 | 500, msg: string}}}
 */
export function lock(data) {
  return service['api-scm']({
    url: '/public/concurrence/lock',
    method: 'post',
    data
  })
}

/**
 * @description 退出编辑详情页
 * @param {{documentType: string, lockCode: string}} data
 * @returns {{code: 200 | 500, msg: string}}}
 */
export function unlock(data) {
  return service['api-scm']({
    url: '/public/concurrence/unlock',
    method: 'post',
    data
  })
}

/**
 * @description 修改排序
 * @param {{field: string, orderType: string, sort:排序规则 0-正序 1-倒序}} data
 * @returns {{code: 200 | 500, msg: string}}}
 */
export function modifyOrderSort(data) {
  return service['api-scm']({
    url: '/public/order/modifyOrderSort',
    method: 'post',
    data
  })
}

/**
 * @description 获取排序
 * @param {{orderType：ORDER_SEPARATE,ORDER_CONFIRM}} data
 * @returns {{code: 200 | 500, msg: string}}}
 */
export function getOrderSort(params) {
  return service['api-scm']({
    url: '/public/order/getOrderSort',
    method: 'get',
    params
  })
}

/**
 *
 * @description 原料辅助申请单下推采购订单校验
 */
export function validatePushDown(data) {
  return service['api-scm']({
    url: '/public/purchase/validatePushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/* @param {{typeEnum: string,userId: string}} params
 */
export async function getDynamicHeader({ typeEnum, userId }) {
  const response = await service['api-scm']({
    crypt: { type: 'none' },
    url: '/allPublic/getDynamicHead/' + typeEnum,
    method: 'post',
    data: userId
  })

  let data = response?.data

  if (data) {
    data = JSON.parse(data)
  }

  return Promise.resolve({ ...(response ?? {}), data })
}

/**
 * @param {{documentType: string, headJson: string, userId: string}} data
 */
export function updateDynamicHeader({ headJson, ...rest }) {
  return service['api-scm']({
    crypt: { type: 'none' },
    url: '/allPublic/updateDynamicHead',
    method: 'post',
    data: {
      ...rest,
      headJson: JSON.stringify(headJson)
    }
  })
}

/**
 * 获取已审核、未作废的销售订单
 */
export function getAuditedAndNoCancelList(params) {
  return service['api-scm']({
    url: '/public/order/getAuditedAndNoCancelList',
    method: 'get',
    params
  })
}

/**
 * 判断当前订单是否存在
 */
export function checkOrderIsExist(params) {
  return service['api-scm']({
    url: '/public/order/checkOrderIsExist',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
export function solvePasteAddress(data) {
  return service['api-scm']({
    url: '/public/order/solvePasteAddress',
    method: 'post',
    data
  })
}
