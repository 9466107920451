/**
 * @module api-scm:orderManage/awaitShipping
 * @description 供应链:订单管理/待发货
 */
import service from '@/service'

/**
 * 列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string
 * }} data
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/get/getList',
    method: 'post',
    data
  })
}

export function getUserPullList(params) {
  return service['api-web']({
    url: '/public/hr/getUserPullList',
    method: 'get',
    params
  })
}

/**
 * 树形表格下拉
 * @param {object} params
 */
export function getListDown(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/get/getPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {string} ids
 * @description  销售出库单生成
 */
export function invoicePrinting(ids, type) {
  return service['api-scm']({
    // url: '/scm/order/orderSeparateDetail/generateWarehouseOut',
    url: '/scm/order/orderSeparateDetail/invoicePrinting', // 5.12经后端沟通替换的
    method: 'post',
    data: { ids, type },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *
 * @param {string} orderCodes
 * @description 京东 生成pdf
 */
export function jdInvoicePrinting(orderCodes) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/jdInvoicePrinting',
    method: 'get',
    params: { orderCodeList: orderCodes },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}
/**
 * @param {(number|string)} ids
 * @description 发货单
 */
export function shipInvoicePrint(ids) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/shipInvoicePrint',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// /**
//  * @param {(number|string)} orderoutCode
//  * @description 供应商发货
//  */
// export function supplierDirect(orderoutCode) {
//   return service['api-scm']({
//     url: '/scm/order/orderSeparateDetail/supplierDirect',
//     method: 'post',
//     data: { orderoutCode }
//   })
// }
/**
 * @param {(number|string)} ids
 * @description 线下物流单打印
 */
export function offlineLogisticsOrderPrinting(ids) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/offlineLogisticsOrderPrinting',
    method: 'post',
    data: ids,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 物流单打印
export function logisticsSinglePrinting(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/logisticsOrderPrintBatch',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// /**
//  * @param {array<string>} params
//  * @description 批量获取面单HTML模板
//  */
// export function getPrintHtml(params) {
//   return service['api-external']({
//     url: '/public/kdniao/getPrintHtml',
//     method: 'get',
//     params,
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }
// 取消物流
export function cancelLogistics(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/logisticsSingleCancel',
    method: 'put',
    data
  })
}
// 物流单复打
export function againPrinting(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/reprintLogisticOrder',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
export function update(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/update',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/cancellation',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{consignee:string,consigneePhone:string,deliveryAddress:string,saleCode:string}} data
 * @description 待发货编辑收货信息
 */
export function updateDeliveryData(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/updateDeliveryData',
    method: 'put',
    data
  })
}

// /**
//  * @param {string} id
//  * @description info
//  */
// export function info(id) {
//   return service['api-scm']({
//     url: '/scm/order/orderSeparateDetail/info/infoAll',
//     method: 'get',
//     id: { orderCode: id }
//   })
// }
// /**
//  * @param {Array<number>} ids
//  * @description 删除
//  */
// export function remove(ids) {
//   return service['api-scm']({
//     url: '/scm/order/orderSeparateDetail/delete',
//     method: 'delete',
//     params: {ids},
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }

/**
 * @description 查询是否存在仅退款的单据
 */
export function queryOnlyRefund(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/get/queryOnlyRefund',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    tipTypes: 'info'
  })
}

/**
 * @description 强制发货
 */
export function forcedDelivery(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparateDetail/forcedDelivery',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 获取所有启用的订单标记
 */
export function getAllEnableOrderMark(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getAllEnableOrderMark',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 物流单打印 => 物流公司下拉
 */
export function getLogisticsCompany(params) {
  return service['api-scm']({
    url: '/public/logistics/findCarrierList',
    method: 'get',
    params: {
      ...params,
      flag: 0
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 获取日志
 */
export function getOperationLog(params) {
  return service['api-scm']({
    url: '/public/order/getOperationLog',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
