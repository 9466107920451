<template>
  <div class="button-group-container">
    <div class="button-group">
      <template v-for="item in buttons">
        <template
          v-if="
            item?.slot &&
            (item?.pmName === null ||
              $_permission([item?.pmName], { by: 'fullname' }))
          "
        >
          <div :key="getItemKey(item)"><slot :name="item.slot" /></div>
        </template>
        <template v-else-if="item?.dropdown">
          <el-dropdown
            v-if="showDropdownButton(item)"
            :key="getItemKey(item)"
            :size="getSize"
            v-on="getDropdownHandler(item)"
          >
            <el-button :size="getSize" v-bind="item?.attrs ?? {}">
              <svg-icon v-if="item?.iconName" :icon-class="item.iconName" />
              {{ getButtonText(item) }}
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown" :size="getSize">
              <template v-for="(dropdownItem, idx) in item.dropdown">
                <el-dropdown-item
                  v-if="showDropdownItemButton(dropdownItem)"
                  :key="idx"
                  :size="getSize"
                  v-bind="dropdownItemProps(dropdownItem)"
                >
                  {{ getButtonText(dropdownItem) }}
                </el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <el-button
            v-if="showButton(item)"
            :key="getItemKey(item)"
            :size="getSize"
            v-bind="item?.attrs ?? {}"
            v-on="getButtonHandler(item)"
          >
            <svg-icon v-if="item?.iconName" :icon-class="item.iconName" />
            {{ getButtonText(item) }}
          </el-button>
        </template>
      </template>
    </div>

    <!-- 工作流弹窗 -->
    <el-dialog
      v-if="mountWorkProcessDialog"
      v-el-drag-dialog
      destroy-on-close
      :fullscreen="false"
      :visible.sync="workProcessDialog.show"
      :title="workProcessDialog.title"
      :top="workProcessDialog.top"
      :width="workProcessDialog.width"
      append-to-body
      custom-class="workProcessDialog"
    >
      <!-- 审核/处理：流程审核（关联查询下拉按钮工作流程：处理环节+流程实例图） -->
      <BacklogAuditTab
        layout="table|diagram"
        :process-id="scope.$_detail_cru_processInstanceId"
        :process-submitter="scope.$_detail_cru_processInstanceSubmitter"
        :document-code="documentCode"
      />
    </el-dialog>

    <!-- 下推抽屉 -->
    <PushDown
      v-if="hasPushDownBtn"
      :scope="scope"
      :visible.sync="pushDownVisible"
      :push-down-opts="pushDownOptions"
      :document-type-opts="documentTypeOpts"
      :return-type-opts="returnTypeOpts"
      :logistics-company-opts="logisticsCompanyOpts"
      :custom-event="pushDownBtn?.customEvent"
      :custom-detail-status="pushDownBtn?.customDetailStatus"
      :redirect-event="pushDownBtn?.redirectEvent"
      :multiple-filter="pushDownBtn?.multipleFilter"
      :skip-validate="pushDownBtn?.skipValidate"
      :show-logistics="pushDownBtn?.showLogistics"
      :disable-default-filter="pushDownBtn?.disableDefaultFilter"
      :default-push-down-type="pushDownBtn?.defaultPushDownType"
      :is-double-check="pushDownBtn?.isDoubleCheck"
      @pushDown="$emit('pushDown', { ...$event, pushDownVisible })"
      @pushDownCallback="$emit('pushDownCallback', $event)"
    />

    <!-- 获取成本信息弹窗 -->
    <GetCostInformation
      v-if="hasgetCostInformationBtn"
      :scope="scope"
      :visible.sync="getCostInformationVisible"
      :get-cost-infomation="getCostInfomation"
      :skip-validate="pushDownBtn.skipValidate"
    />
  </div>
</template>

<script>
import { get as getCookie } from '@/utils/cookies'
import { isArray, isNumeric, isPlainObject, isString } from '@/utils/validate'

import BacklogAuditTab from '@/components/BacklogAuditTab'

import {
  dropdownRender,
  dropdownHandler,
  dropdownListener
} from './mixins/dropdown'
import { buttonRender, buttonHandler, buttonListener } from './mixins/button'
import workProcess from './mixins/workProcess'
import pushDown from './mixins/pushDown'
import getCostInformation from './mixins/getCostInformation'
import { createUniqueString } from '@/utils/string'
export default {
  name: 'ButtonGroup',
  components: {
    PushDown: () => import('@/components/PushDown'),
    GetCostInformation: () => import('@/components/GetCostInformation'),
    BacklogAuditTab
  },
  mixins: [
    /**
     * 混入流程图弹窗
     */
    workProcess,
    /**
     * 混入下推抽屉
     */
    pushDown,
    /**
     * 混入获取成本信息弹窗
     */
    getCostInformation,
    /**
     * 混入下拉按钮组
     */
    dropdownRender, // 渲染
    dropdownHandler, // 事件处理器
    dropdownListener, // 事件绑定(监听)
    /**
     * 混入按钮
     */
    buttonRender, // 渲染
    buttonHandler, // 事件处理器
    buttonListener // 事件绑定(监听)
  ],
  props: {
    buttonGroup: {
      type: Array,
      default: () => []
    },
    tableLinkRef: {
      type: String,
      default: ''
    },
    documentCode: {
      type: String,
      default: ''
    },
    scope: {
      type: Object,
      default: () => ({})
    },
    direction: {
      type: String,
      default: 'row'
    }
  },
  data() {
    return {}
  },
  computed: {
    getSize() {
      return (
        this.size || this.$store.getters.size || getCookie('size') || 'mini'
      )
    },
    buttons() {
      return this.direction === 'row-reverse'
        ? [...this.buttonGroup].reverse()
        : this.buttonGroup
    },
    getItemKey() {
      return (item) =>
        (item?.label ?? item?.evtName ?? '') + '-' + createUniqueString()
    },
    eventContext() {
      return (
        this.scope?._self ||
        (isPlainObject(this.$parent) && Object.keys(this.$parent) > 0
          ? this.$parent
          : this)
      )
    },
    /**
     * currentPageType
     * @returns {('list'|'detail'|'create'|'')}
     */
    currentPageType() {
      // eslint-disable-next-line no-unused-vars
      const { fullPath } = this.$route
      return this.$pageType.currentPageType(this.$route)
    },
    /**
     * currentDetailProcessing
     * @returns {boolean}
     * @description 当前详情是否是审核处理页面
     */
    currentDetailProcessing() {
      return !!(
        this.currentPageType === 'detail' &&
        isPlainObject(this.scope?.$_detail_cru_processInfo)
      )
    }
  },

  created() {},
  methods: {
    loading(state) {
      this.$emit('update:loading', state)
    },
    /**
     * @param {*} item button, dropdown, dropdown-item
     * @param {('parent'|'child')} buttonType
     * @returns
     */
    checkPermission(item) {
      const pm = item.pmName
      const pmCtrl = item.pmCtrl || {}
      const { by = 'fullname', type = 'every' } = pmCtrl

      if (isArray(pm) || isString(pm)) {
        const hasPermission = this.$_permission(isArray(pm) ? pm : [pm], {
          by,
          type
        })
        return hasPermission
      } else {
        console.info('"pmName" 无效值', item.pmName, item)
        return false
      }
    },
    /**
     * 按钮/下拉项 文案
     * @param {*} item button, dropdown, dropdown-item
     * @returns {string}
     */
    getButtonText(item) {
      const defaultName = '🔘'

      const label = isString(item.label) && item.label ? item.label : ''
      if (label) return label

      const evtName = isString(item.evtName) && item.evtName ? item.evtName : ''
      if (item.pmName === null) return evtName || defaultName

      let permissionName =
        isString(item.pmName) && item.pmName
          ? this.$_permission_name(item.pmName)
          : ''

      if (permissionName && permissionName === 'N/A') {
        permissionName = ''
        console.error('【buttonGroup】losed permission name')
      }

      return permissionName || evtName || defaultName
    },
    /**
     * @param {boolean} [full=true]
     * @param {boolean} [reserveFull=false]
     * @description 当前 vxe 版本问题， full、reverseFull 无效果，需要获取整个表格的勾选项，手动合并两个集合
     * - getCheckboxRecords: 只能获取当前页的勾选项；
     * - getCheckboxReserveRecords：只能获取除当前页的勾选项
     */
    getSelections() {
      if (this.currentPageType === 'list') {
        if (isString(this.tableLinkRef) && this.tableLinkRef.trim()) {
          const $tableRef = this.scope?.$refs?.[this.tableLinkRef]
          const reserve =
            typeof $tableRef?.checkboxConf?.reserve === 'boolean'
              ? $tableRef.checkboxConf.reserve
              : true
          const $table = $tableRef?.$table
          if (typeof $table?.getCheckboxRecords === 'function') {
            // vxe-table
            return reserve === true
              ? [
                  ...$table.getCheckboxRecords(false),
                  ...$table.getCheckboxReserveRecords(false)
                ]
              : [...$table.getCheckboxRecords(false)]
          } else {
            console.warn(
              "【buttonGroup】losed vxe-table's 'getCheckboxRecords' method"
            )
          }
        } else console.warn('button group without "table-link-ref" prop')

        // common
        let selectionFromListMixin = this.scope?.list_crud_listSelection || []

        if (typeof this.scope?.getCheckedRows === 'function') {
          const result = this.scope.getCheckedRows()
          if (result?.length > 0) {
            selectionFromListMixin = result
          }
        }

        if (selectionFromListMixin.length === 0) {
          console.warn('Empty selections')
          console.groupCollapsed('see details')
          console.warn(
            '1. If <button-group> with the <base-inline-edit-table> on the page'
          )
          console.warn("2. If use selections for button-group's operation")
          console.warn('Please set the "table-link-ref" on <button-group>')
          console.warn(
            'e.g: <button-group ... table-link-ref="{{ref of base-inline-edit-table}}" .../>'
          )
          console.groupEnd()
        }

        return selectionFromListMixin
      } else {
        console.error('【buttonGroup】only list page has checkbox selections')
        return []
      }
    },
    /**
     * 列表页：获取表格选中 selection
     */
    getIds() {
      if (this.currentPageType === 'list') {
        return this.getSelections().map((v) => {
          if (!v.id) {
            console.error('list item lost id,Please check!')
          }
          return v.id || v
        })
      } else if (this.currentPageType === 'detail') {
        return [this.scope.id]
      } else console.error('【buttonGroup】Illegal route path, not support yet')
    },
    /**
     * @param {{config:object,evtName:string,methodName:string,button:any,parent:any}}
     * @returns {({success:boolean,result:(null|any),message:string,description:string}|undefined)}
     */
    async commonRequest({ config, evtName, methodName, button, parent }) {
      const {
        reload = false,
        confirm = false,
        confirmPayload = {},
        xhr = {}
      } = button?.config || {}

      const { api } = xhr
      this.loading(false)
      if (!isString(api) || api.trim() === '') {
        return {
          success: false,
          result: null,
          feedback: 'console',
          message: `${evtName}: 未在 buttonGroup's item 上配置正确的 api request func name`,
          description: `${evtName}: error api:${api} field of of buttonGroup’s item`
        }
      }

      try {
        if (confirm === true) {
          const {
            title = '提示',
            message = `是否确定${evtName}?`,
            type = 'warning'
          } = confirmPayload
          const isConfirm = await this.$confirm(message, title, { type })
          if (!isConfirm) {
            return {
              success: false,
              result: null,
              feedback: 'quiet',
              message: `${evtName}: 弹窗二次确认取消`,
              description: `${methodName}: cancel by user`
            }
          }
        }
        this.loading(true)

        /**
         * 操作请求前刷新当前列表
         * 例如：导出,...
         */
        if (reload === true) {
          const { $_list_crud_clickSearch } = this.scope
          if (typeof $_list_crud_clickSearch === 'function') {
            await $_list_crud_clickSearch()
          }
        }

        const { apiList = {} } = this.scope
        const requestFunc = apiList[api]
        console.log(requestFunc, 'requestFunc====>')

        if (typeof requestFunc !== 'function') {
          return {
            success: false,
            result: null,
            feedback: 'console',
            message: `${evtName}: 未配置模块对应的 service api`,
            description: `${evtName} : losed "${api}" function of service`
          }
        }

        const { start = null, end = null } = xhr
        const [err, send] = await this.getSendFromButton(button, evtName)
        if (err) return err
        const requestSend = send ?? {}
        typeof start === 'function' && (await start(requestSend))
        console.log('request:', requestSend)
        const result = await requestFunc(requestSend)
        console.log('response:', result)
        typeof end === 'function' && (await end(result))

        const { code, data, msg, description } = result
        return {
          success: code === 200,
          result:
            code === 200 ? (data instanceof Blob ? result : data) : result,
          feedback: code === 200 ? undefined : 'console',
          message: `${evtName}${code === 200 ? '成功' : '失败'}`,
          description: `${methodName} ${
            code === 200 ? 'success' : 'fail'
          } ${msg} ${description}`
        }
      } catch (error) {
        return {
          success: false,
          result: error,
          feedback: 'toast',
          message: `${evtName}失败`,
          description: `${methodName} error`
        }
      }
    },
    /**
     * @return {Array} [error, result]
     */
    async getSendFromButton(button, evtName) {
      if (typeof button?.config?.xhr?.send === 'undefined') {
        return [null, null]
      }

      let _err = null

      const {
        config: {
          xhr: { send }
        }
      } = button

      const _send =
        typeof send === 'function'
          ? await send(
              this.getSelections(),
              () => this.scope?._self ?? this.scope ?? {}
            )
          : send

      if (isPlainObject(_send)) {
        if (Object.keys(_send).length === 0) {
          console.warn('Empty "send"', _send)
        }
      } else {
        _err = {
          success: false,
          result: null,
          feedback: 'console',
          message: `${evtName}: 未在 buttonGroup's item 上配置正确的 send 请求参数对象`,
          description: `${evtName}: invalid "send" field of buttonGroup’s item, only support object or function that return object`
        }
      }

      return [_err, _send]
    },
    /**
     * @param {{config:object,evtName:string,methodName:string,button:any,parent:any}}
     * @returns {({success:boolean,result:(null|any),message:string,description:string}|undefined)}
     */
    async commonHandler({ config, evtName, methodName, button, parent }) {
      this.loading(false)

      if (this.currentPageType === 'list') {
        const selections = this.getSelections()
        if (!selections.length) {
          return {
            success: false,
            result: null,
            feedback: 'toast',
            message: '请先勾选数据项',
            description: 'losed list selections'
          }
        }
        console.log('selections:', selections)
      }

      const { apiName = '' } = config
      if (!apiName) {
        return {
          success: false,
          result: null,
          feedback: 'console',
          message: `${evtName}: 未配置对应的 apiName 映射`,
          description: `${evtName}: losed apiName field of ./config.js`
        }
      }

      const requestFunc = this.scope?.apiList?.[apiName]
      if (typeof requestFunc !== 'function') {
        return {
          success: false,
          result: null,
          feedback: 'console',
          message: `${evtName}: 未配置模块对应的 service api`,
          description: `${evtName} : losed "${apiName}" function of service`
        }
      }
      const { confirm = true, confirmPayload = {} } = button?.config ?? {}

      if (confirm === true) {
        const {
          title = '提示',
          message = `是否确定${evtName}?`,
          type = 'warning'
        } = confirmPayload
        const isConfirm = await this.$confirm(message, title, { type })

        if (!isConfirm) {
          return {
            success: false,
            result: null,
            feedback: 'quiet',
            message: `${evtName}: 弹窗二次确认取消`,
            description: `${methodName}: cancel by user`
          }
        }
      }

      this.loading(true)
      try {
        /**
         * default send id or ids
         */
        const defaultSend =
          this.currentPageType === 'detail' && config?.idType === 'number'
            ? this.scope.id
            : this.getIds().join()
        /**
         * custom button config.xhr.send
         */
        const [err, send] = await this.getSendFromButton(button, evtName)
        if (err) return err
        const requestSend = send ?? defaultSend
        const { start = null, end = null } = button?.config?.xhr ?? {}
        typeof start === 'function' && (await start(requestSend))
        const res = await requestFunc(requestSend)
        typeof end === 'function' && (await end(res))

        const { code, data, msg, description } = res
        button?.config?.showRawMsg === true && this.$emit('showDialog', res)
        return {
          success: code === 200,
          result: code === 200 ? data : res,
          feedback: code === 200 ? undefined : 'console',
          message:
            button?.config?.showRawMsg === true
              ? `${msg}`
              : `${evtName}${code === 200 ? '成功' : '失败'}`,
          description: `${methodName} ${
            code === 200 ? 'success' : 'fail'
          } ${msg} ${description}`
        }
      } catch (error) {
        return {
          success: false,
          feedback: 'toast',
          result: error,
          message: `${evtName}失败`,
          description: `${methodName} error`
        }
      }
    },
    /**
     * 通用默认回调成功
     * 1. 覆盖：配置了外部回调 item.callback.success 的话，将覆盖此回调
     * 2. 叠加：emit 出去的 success , fail 会叠加回调，并不会覆盖
     */
    async handleSuccess({
      res: response,
      // feedback,
      config,
      parent,
      button,
      evtName,
      methodName,
      result,
      message,
      description
    }) {
      /**
       * @description 对接金碟接口才有的提示框
       * @see task:954
       */
      if (button?.isJindie) {
        this.$confirm(
          `对应的${button.tips}，需要在【金碟系统】中手动作废，请及时处理不要遗漏！`,
          {
            confirmButtonText: '好的，我知道了',
            showCancelButton: false,
            showClose: false,
            modal: false,
            closeOnClickModal: false,
            type: 'warning'
          }
        )
      }

      if (config?.defaultCallback === true) {
        this.execDefaultCallback({
          response,
          // feedback,
          config,
          parent,
          button,
          evtName,
          methodName,
          result,
          message,
          description
        })
      }

      // if (feedback === 'toast') {
      //   message && this.$message.success(message)
      // } else if (feedback === 'custom') {
      //   if (typeof button?.config?.xhr?.message === 'function') {
      //     button.config.xhr.message({ status: 'success', ...res })
      //   } else console.error('Invaild button.config.xhr.message')
      // }
      message && this.$message.success(message)
      console.groupCollapsed('handleSuccess')
      console.log('evtName:', evtName)
      console.log('methodName:', methodName)
      console.log('result:', result)
      console.log('message:', message)
      console.log('description:', description)
      console.log('button:', button)
      console.log('parent:', parent)
      console.groupEnd()
    },
    /**
     * 通用默认回调失败
     * 1. 配置了外部回调 item.callback.fail 的话，将覆盖此回调
     * 2. emit 出去的 success , fail 会叠加回调，并不会覆盖
     */
    handleFail({
      res,
      config,
      parent,
      button,
      evtName,
      methodName,
      result,
      feedback = 'console',
      message,
      description
    }) {
      if (result?.code === 101) {
        if (config?.defaultCallback === true) {
          this.execDefaultCallback({
            // res,
            // feedback,
            config,
            parent,
            button,
            evtName,
            methodName,
            result,
            message,
            description
          })
        }
      }

      if (feedback === 'toast') {
        message && this.$message.error(message)
      } else if (feedback === 'custom') {
        if (typeof button?.config?.xhr?.message === 'function') {
          button.config.xhr.message({ status: 'fail', ...res })
        } else console.error('Invaild button.config.xhr.message')
      }

      if (feedback !== 'quiet') {
        console.groupCollapsed('handleFail' + ' ' + feedback)
        console.error('evtName:', evtName)
        console.error('methodName:', methodName)
        console.error('result:', result)
        console.error('message:', message)
        console.error('description:', description)
        console.error('button:', button)
        console.error('parent:', parent)
        console.error('page:', window?.location?.pathname)
        console.error('url:', window?.location?.href)
        console.error('scope:', this.scope)
        console.groupEnd()
      }
    },
    async execDefaultCallback({
      response,
      // feedback,
      config,
      parent,
      button,
      evtName,
      methodName,
      result,
      message,
      description
    }) {
      const ERR_INFO_SET = [
        parent,
        button,
        evtName,
        methodName,
        result,
        message,
        description,
        this.scope,
        window?.location?.pathname,
        window?.location?.href,
        window?.location?.search,
        this?.$store?.getters?.name
      ]

      if (this.currentPageType === 'list') {
        const { $_list_crud_table_refresh } = this.scope || this
        typeof $_list_crud_table_refresh === 'function'
          ? await $_list_crud_table_refresh({
              selectionClear: true
            })
          : console.error.apply(null, [
              'Losed "$_list_crud_table_refresh"',
              ...ERR_INFO_SET
            ])
      } else if (this.currentPageType === 'detail') {
        const {
          $_detail_cru_processInfo,
          $_detail_cru_exit,
          id,
          queryDetailById
        } = this.scope || this

        // 待办列表跳转到详情的情况
        if ($_detail_cru_processInfo) {
          typeof $_detail_cru_exit === 'function'
            ? $_detail_cru_exit()
            : console.error.apply(null, [
                'Losed "$_detail_cru_exit"',
                ...ERR_INFO_SET
              ])
        } else {
          if (typeof queryDetailById === 'function') {
            // 目前只有 反审核 需要根据 返回的 新单据 ID 查询，
            // TODO: 后续需要可配置

            // 由于提交后，路由变化会调用获取详情接口，因此此处无需重复调用了
            if (
              ['提交'].includes(evtName) &&
              this.$route.path?.includes('sellOrder/detail')
            ) {
              if (response?.code === 200) {
                let status = 2 // 审核中
                status =
                  typeof status === 'number'
                    ? status
                    : isNumeric(this.$route.query.status)
                    ? this.$route.query.status * 1
                    : this.$route.query.status
                const { path, query } = this.$route
                const current = path.split('/')
                current.pop()
                const base = current.join('/')
                this.$router.replace({
                  path: `${base}/${id}`,
                  query: { ...query, status, mode: 'view' }
                })

                return
              }
            }

            if (evtName === '反审核') {
              if (typeof result !== 'undefined' && result !== null) {
                await queryDetailById(result)
              } else {
                console.error.apply(null, [
                  'Losed "result" for query detail',
                  ...ERR_INFO_SET
                ])
              }
            } else {
              if (typeof id !== 'undefined' && id !== null) {
                await queryDetailById(id)
              } else {
                console.error.apply(null, [
                  'Losed "id" for query detail',
                  ...ERR_INFO_SET
                ])
              }
            }
          } else {
            console.error.apply(null, [
              'Losed "queryDetailById" func',
              ...ERR_INFO_SET
            ])
          }
        }
      } else {
        console.error.apply(null, [
          'Unknown page type',
          this.currentPageType,
          ...ERR_INFO_SET
        ])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex-direction: v-bind(direction);

  .el-button + .el-button {
    margin-left: 0px;
  }
}
</style>
