/**
 * @module plugins:element-ui
 */
import Vue from 'vue'

import { default as Element, Message } from 'element-ui'

import { get as getCookie } from '@/utils/cookies'
import { isPlainObject, isString } from '@/utils/validate'

import 'element-ui/lib/theme-chalk/display.css'
import '@/styles/element-variables.scss'

// import enLang from 'element-ui/lib/locale/lang/en' // 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
/**
 * @description 对话框增加拖拽功能
 */
import elDragDialog from '@/directive/el-drag-dialog' // base on element-ui
Vue.use(elDragDialog.install)

Vue.use(Element, {
  size: getCookie('size') || 'mini' // set element-ui default size
  // locale: enLang // 如果使用中文，无需设置，请删除
})

/**
 * @description: 在 MSG_DEFAULT_CONF 中可以全局配置 Message 默认参数
 * @see https://element.eleme.io/#/zh-CN/component/message#options
 */
const MSG_DEFAULT_CONF = {
  showClose: true
  // ...
}
const message = (options) => {
  try {
    if (isPlainObject(options)) {
      return Message({ ...MSG_DEFAULT_CONF, ...options })
    } else if (isString(options)) {
      return Message({ ...MSG_DEFAULT_CONF, message: options })
    } else return Message(options)
  } catch (error) {
    console.error(error)
    return Message(options)
  }
}
Object.entries(Message).forEach(([origInstMethodName, origInstMethod]) => {
  message[origInstMethodName] = (options) => {
    try {
      if (isPlainObject(options)) {
        return origInstMethod({ ...MSG_DEFAULT_CONF, ...options })
      } else if (isString(options)) {
        return origInstMethod({ ...MSG_DEFAULT_CONF, message: options })
      } else return origInstMethod(options)
    } catch (error) {
      console.error(error)
      return origInstMethod(options)
    }
  }
})
Vue.prototype.$message = message

/**
 * @description: 统一格式化去除input首尾空格(包含不能输入空格键)
 */
const trimMixin = {
  watch: {
    value(val) {
      isString(val) && val && this.$emit('input', val.trim())
    }
  },
  methods: {
    /**
     * @param {string} eventName
     */
    listen(eventName) {
      this.$on(eventName, (value) => void this.$emit('input', value.trim()))
    }
  }
}
/**
 * @param {string} compName
 * @param {string} eventName
 * @param {Function} compClass
 */
const trimComp = (compName, eventName, compClass) => {
  Vue.component(compName, {
    extends: compClass,
    mixins: [trimMixin],
    created() {
      this.listen(eventName)
    }
  })
}
trimComp('el-input', 'change', Element.Input)
trimComp('el-select', 'select', Element.Select)
// trimComp('xxx', 'xxx', Element.Xxx)
// ...

/**
 * @description: xxx
 */
