import { deleteChildren } from '@/utils/tree'
import { isArray } from '@/utils/validate'
const state = () => ({
  nextRoute: null,
  prevRoute: null,
  /**
   * @type {Record<string,array>}
   * @description axios 拦截器中的错误提示实例队列，关闭信息, 存储的分割维度 res.code
   * @example { 500: [message,message,...] , 401: ...}
   */
  axiosErrMsgs: {},
  /**
   * @type {boolean}
   * @description 是否在路由跳转前记录上一次路由信息
   */
  disablePrevRoute: false,
  /**
   * @type {Function}
   * @description base-container 组件内部更新布局（表格高度）的处理方法
   */
  globalResizeHandler: null,
  /**
   * @type {boolean}
   * @description 当前列表页面查询是否有数据
   */
  currentHasListData: false,
  /**
   * @type {boolean}
   * @description  详情页面 routerReturnMixin 是否正在 loading
   */
  loadingByRouterReturn: false
})
const getters = {
  /* app 应用 */
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  errorLogs: (state) => state.errorLog.logs,

  /* 用户信息 */
  id: (state) => state.user.id, // ID
  key: (state) => state.user.key, // aes key
  name: (state) => state.user.name, // 昵称
  realname: (state) => state.user.realname, // 姓名
  token: (state) => state.user.token, // 令牌
  avatar: (state) => state.user.avatar, // 头像
  roles: (state) => state.user.roles, // 角色
  dept: (state) => state.user.deptId, // 部门ID
  deptParentId: (state) => state.user.deptParentId,
  depts: (state) => state.user.deptPath, // 部门（父级-子级-）
  job: (state) => state.user.jobId, // 职位ID

  /* 权限控制 */
  menus: (state) => state.permission.menus, // 当前角色菜单（动态路由）
  pmList: (state) => state.permission.list, // 当前角色所有权限列表
  pmIdx: (state) => state.permission.idIndex, // 当前角色所有权限 ID 索引 （菜单，资源）
  pmIds: (state) => Object.keys(state.permission.idIndex), // 当前角色所有权限 ID（菜单，资源）
  pmNamex: (state) => state.permission.nameIndex, // 当前角色所有权限 名称 索引 （菜单，资源）
  pmNames: (state) => Object.keys(state.permission.nameIndex), // 当前角色所有权限 名称（菜单，资源）
  nestedNavsPidx: (state) => state.permission.resources.navs, // 当前角色资源 PID 索引（内嵌导航）
  btnOperationsPidx: (state) => state.permission.resources.btns, // 当前角色资源 PID 索引（按钮操作）

  /* 省市区 */
  PCD: (state) => state.pcd.data, // 接口远程数据：全量省市区坐标信息
  // provinceAndCityData: (state) => state.pcd.provinceAndCityData, // 本地数据：省市二级联动数据（不带“全部”选项）
  // provinceAndCityDataPlus: (state) => state.pcd.provinceAndCityDataPlus, // 本地数据：省市二级联动数据（带“全部”选项）, "全部" 选项绑定的 value 是空字符串
  // regionData: (state) => state.pcd.regionData, // 本地数据：省市区三级联动数据（不带“全部”选项）
  // regionDataPlus: (state) => state.pcd.regionDataPlus, //  本地数据：省市区三级联动数据（带“全部”选项）, "全部" 选项绑定的 value 是空字符串
  // CodeToText: (state) => state.pcd.CodeToText, // 本地数据：CodeToText 是个大对象，属性是区域码，属性值是汉字
  // TextToCode: (state) => state.pcd.TextToCode, // 本地数据：TextToCode 是个大对象，属性是汉字，属性值是区域码

  /* 下拉选项 */
  userOpts: (state) => state.filterOpts.user, // 用户 (value:id)
  userNameOpts: (state) => state.filterOpts.userName, // 用户 (value:name)
  roleOpts: (state) => state.filterOpts.role, // 角色
  politicsOpts: (state) => state.filterOpts.politics, // 政治面貌
  nationOpts: (state) => state.filterOpts.nation, // 民族
  educationOpts: (state) => state.filterOpts.education, // 学历
  userbydepartmentOpts: (state) => state.filterOpts.userbydepartment, // 采购部门下用户下拉框
  companyOpts: (state) => state.filterOpts.company, // 公司/组织【id】
  companyNameOpts: (state) => state.filterOpts.companyName, // 公司/组织【name】
  taxRateOpts: (state) => state.filterOpts.taxRate, // 默认税率 code
  taxRateIdOpts: (state) => state.filterOpts.taxRateId, // 默认税率 id
  settlementCurrencyOpts: (state) => state.filterOpts.settlementCurrency, // 结算币种
  sellUserOpts: (state) => state.filterOpts.sellUser, // 销售人员
  salesDeptUserOpts: (state) => state.filterOpts.salesDeptUser, // 销售人员
  salesDeptOpts: (state) => state.filterOpts.salesDept, // 销售部门
  supplierGroupOpts: (state) => state.filterOpts.supplierGroup, // 客户分组
  supplierBuyerOpts: (state) => state.filterOpts.supplierBuyer, // 采购员
  managementSpecialistOpts: (state) => state.filterOpts.managementSpecialist, // 供应商管理专员
  processDefinitionOpts: (state) => state.filterOpts.processDefinition, // 流程定义
  locationCodeOpts: (state) => state.filterOpts.locationCode, // 库存代码
  inventoryInfoOpts: (state) => state.filterOpts.inventoryInfo, // 获取库存明细信息(含批次号)
  allInventoryProductInfoByBatchCodeOpts: (state) =>
    state.filterOpts.allInventoryProductInfoByBatchCode, // 根据调出仓库、调出货主、商品编码、库位代码获取库存数量、可配数信息
  modeDespatchOpts: (state) => state.filterOpts.modeDespatch, // 发货方式
  productSpecialistOpts: (state) => state.filterOpts.productSpecialist, // 获取产品专员下拉框
  productLabelManagelistOpts: (state) =>
    state.filterOpts.productLabelManagelist, // 标签下拉框
  unitOfMeasurementOpts: (state) => state.filterOpts.unitOfMeasurement, // 单位下拉框 （全量）
  unitOfMeasurementAvailableOpts: (state) =>
    state.filterOpts.unitOfMeasurementAvailable, // 单位下拉框(可用)
  costItemSalesOpts: (state) => state.filterOpts.costItemSales, // 费用项目（销售）
  costItemPurchaseOpts: (state) => state.filterOpts.costItemPurchase, // 费用项目（采购）
  productCategoryOpts: (state) => state.filterOpts.productCategory, // 产品分分类下拉框
  sellTerritoryOpts: (state) => state.filterOpts.sellTerritory, // 不可售地域
  productAncillaryCodeOpts: (state) => state.filterOpts.productAncillaryCode, // 商品福编码
  productLabelManageOpts: (state) => state.filterOpts.productLabelManageOpts, // 标签管理
  taxSystemCodeOpts: (state) => state.filterOpts.taxSystemCode, // 税收制度
  taxTypeCodeOpts: (state) => state.filterOpts.taxTypeCode, // 税种
  districtOpts: (state) => state.filterOpts.district, // 基础资料//税收制度/地区
  warehouseStatusTypeOpts: (state) => state.filterOpts.warehouseStatusType, // 基础资料/仓库基本信息/库存状态
  asyncClearingFormOpts: (state) => state.filterOpts.asyncClearingForm, // 结算方式下拉框（结算组织 筛选）
  clearingFormUnionOpts: (state) => state.filterOpts.clearingFormUnion, // 结算方式下拉框(组织（类型、ID）和 结算组织 同时筛选)
  asyncMutipileClearingFormOpts: (state) =>
    state.filterOpts.asyncMutipileClearingForm, // 结算方式
  companyInfoOpts: (state) => state.filterOpts.companyInfo, // 异步结算方式下拉框,
  companyInfoForEnableOpts: (state) => state.filterOpts.companyInfoForEnable, // 已启用的销售组织
  warehouseOpts: (state) => state.filterOpts.warehouse, // 下拉选项: 仓库 CODE
  warehouseIdOpts: (state) => state.filterOpts.warehouseId, // 下拉选项: 仓库 ID
  warehouseNameOpts: (state) => state.filterOpts.warehouseName, // 下拉选项: 仓库 NAME
  warehouseAllOnOpts: (state) => state.filterOpts.warehouseAllOn, // 下拉选项: 所已审核且启用的仓库
  warehouseExcludeDirectOpts: (state) =>
    state.filterOpts.warehouseExcludeDirect, // 下拉选项：所有启用已审核的仓库 排除供应商直发仓，供应商库存直发仓
  provinceOpts: (state) => deleteChildren(state.filterOpts.province), // 下拉选项: 获取省市
  storePullOpts: (state) => state.filterOpts.storePull, // 下拉选项: 商店名称
  cloudWarehouseOpts: (state) => state.filterOpts.cloudWarehouse, // 下拉选项 获取所有云仓下拉框
  companyInfoFinanceOpts: (state) => state.filterOpts.companyInfoFinance, // 下拉选项 获取组织对应的财务下拉框
  shipperOpts: (state) => state.filterOpts.shipper, // 申请库存盘点表 调入/调出(货主)
  logisticsCompanyOpts: (state) => state.filterOpts.logisticsCompany, //  物流商
  jdlogisticsOpts: (state) => state.filterOpts.jdlogistics, //  物流商(京东快递物流商)
  logisticsNoContactOpts: (state) => state.filterOpts.logisticsNoContact, //  物流公司(能够带出账期基本信息)
  paymentDaysNoteOpts: (state) => state.filterOpts.paymentDaysNote, // 账期备注
  logisticsNumberOpts: (state) => state.filterOpts.logisticsNumber, //  物流单号
  carrierOpts: (state) => state.filterOpts.carrier, // 承运商
  logisticsInfoOpts: (state) => state.filterOpts.logisticsInfo, // 物流查询
  inventoryCodeOpts: (state) => state.filterOpts.inventoryCode, // 库存盘点  库位下拉框
  boundTypeCodeOpts: (state) => state.filterOpts.boundTypeCode, // 出入库类型
  inBboundTypeCodeOpts: (state) => state.filterOpts.inBoundTypeCode, // 入库类型
  outBoundTypeCodeOpts: (state) => state.filterOpts.outBoundTypeCode, // 出库类型
  carModelOpts: (state) => state.filterOpts.carModel, // 车型
  feeItemOpts: (state) => state.filterOpts.feeItem, // 费用项目
  accountBookOpts: (state) => state.filterOpts.accountBook, // 账簿
  subjectsCategoryOpts: (state) => state.filterOpts.subjectsCategory, // 科目类别
  amountOperatorOpts: (state) => state.filterOpts.amountOperator, // 实收金额
  dealingClientOpts: (state) => state.filterOpts.dealingClient, // 往来单位
  constitemOpts: (state) => state.filterOpts.constitem, // 账户类型
  accountCalendarAllOpts: (state) => state.filterOpts.accountCalendarAll, // 会计日历
  dictOpts: (state, getters) => getters['filterOpts/dictDropdownMap'].opts, // 字典
  deptOpts: (state, getters) => getters['filterOpts/deptDropdownMap'].opts, // 部门
  jobOpts: (state, getters) => getters['filterOpts/jobDropdownMap'].opts, // 职位
  logisticsCarrierOpts: (state) => state.filterOpts.logisticsCarrier,

  /* 下拉选项配套索引 */
  deptIdxs: (state, getters) => getters['filterOpts/deptDropdownMap'].idxs, // 部门
  dictIdxs: (state, getters) => getters['filterOpts/dictDropdownMap'].idxs, // 字典
  jobIdxs: (state, getters) => getters['filterOpts/jobDropdownMap'].idxs, // 职位

  /* 目录/分组/树列表 */
  deptTree: (state) => state.sysTree.department, // 系统/部门
  jobTree: (state) => state.sysTree.job, // 系统/职位
  customerTree: (state) => state.sysTree.customer, // 资料/客户分组
  goodsTree: (state) => state.sysTree.goods, // 资料/商品分组
  supplierTree: (state) => state.sysTree.supplier, // 资料/供应商
  processCategoryTree: (state) => state.sysTree.processCategory, // 系统/流程/分类
  constitemTree: (state) => state.sysTree.constitem, // 财务/总账/资料/费用项目分组树
  vouchertemplateTree: (state) => state.sysTree.vouchertemplate, // 财务/总账/资料/凭证模板分组树
  dockingKingdeeTree: (state) => state.sysTree.dockingKingdee, // 系统对接/金蝶对接中心
  dockingThirdPartyTree: (state) => state.sysTree.dockingThirdParty, // 系统对接/第三方对接中心
  xfsProduceTree: (state) => state.sysTree.xfsProduce // 资料/ xfs生产
}

const mutations = {
  SET_NEXT_ROUTE(state, route) {
    state.nextRoute = route
  },
  SET_PREV_ROUTE(state, route) {
    state.prevRoute = route
  },
  SET_PREV_ROUTE_DISABLE_FLAG(state, flag) {
    state.disablePrevRoute = flag
  },
  SET_GLOBAL_RESIZE_HANDLER(state, handler) {
    state.globalResizeHandler = handler
  },
  SET_CURRENT_HAS_LIST_FLAG(state, flag) {
    state.currentHasListData = flag
  },
  SHARE_RR_LOADING(state, loading) {
    state.loadingByRouterReturn = loading
  },
  CLEAR_AXIOS_ERR_MSGS(state, { key }) {
    if (isArray(state.axiosErrMsgs?.[key])) {
      state.axiosErrMsgs[key].forEach(
        (m) => typeof m?.close === 'function' && m.close()
      )
      state.axiosErrMsgs[key] = []
    }
  },
  SET_AXIOS_ERR_MSGS(state, { key, value }) {
    if (!isArray(state.axiosErrMsgs?.[key])) state.axiosErrMsgs[key] = []

    state.axiosErrMsgs[key].push(value)
  }
}
const actions = {
  setPrevRouteIncognito({ commit }, flag) {
    commit('SET_PREV_ROUTE_DISABLE_FLAG', flag)
  },
  setPrevRouteRecord({ commit }, route) {
    commit('SET_PREV_ROUTE', route)
  },
  setNextRouteRecord({ commit }, route) {
    commit('SET_NEXT_ROUTE', route)
  },
  setGlobalResizeHandler({ commit }, handler) {
    commit('SET_GLOBAL_RESIZE_HANDLER', handler)
  },
  setCurrentHasListData({ commit }, flag) {
    commit('SET_CURRENT_HAS_LIST_FLAG', flag)
  },
  clearAxiosErrMsgsByKey({ commit }, { key }) {
    commit('CLEAR_AXIOS_ERR_MSGS', { key })
  },
  setAxiosErrMsgsByKey({ commit }, { key, value }) {
    commit('SET_AXIOS_ERR_MSGS', { key, value })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
