/**
 * @module api-web:LabelManage
 */
import service from '@/service'

/**
 * 供应货授权   表查询
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/supplyAuthorization/get/getList',
    method: 'get',
    params
  })
}
/**
 * 供应货授权 日志查询
 * @param {{productCode:string,supplierCode:string}}
 */
export function getLogs(params) {
  return service['api-web']({
    url: '/data/supplyAuthorization/get/logs',
    method: 'get',
    params
  })
}

/**
 * 供应货授权 新增
 */
export function create(data) {
  return service['api-web']({
    url: '/data/supplyAuthorization/save',
    method: 'post',
    data,
    tipTypes: 'info,error'
  })
}
/**
 * 供应货授权 修改
 */
export function update(data) {
  return service['api-web']({
    url: '/data/supplyAuthorization/update',
    method: 'post',
    data
  })
}

/**
 * 供应货授权   详情
 */
export function info(id) {
  return service['api-web']({
    url: '/data/supplyAuthorization/info',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {{params:Object}}
 * @description 导出
 */
export function exportData(data) {
  return service['api-web']({
    url: '/data/supplyAuthorization/sendDataInfo/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * 提交
 * @param {{ids:string,isUnbind:boolean,reSubmit:boolean}} data
 * @description isUnbind 只有当解除绑定的时候为true  resubmit 只有当解除绑定后重新绑定并提交的时候为true,
 */
export function submit(data) {
  return service['api-web']({
    url: '/data/supplyAuthorization/submit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    /**
     * @see #1563
     * 按照需求自定义提示框
     */
    tipTypes: data?.isUnbind === false ? 'error' : 'warning,info,error'
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/supplyAuthorization/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核完成
 * @param {{id:string,state:number}} data
 */
export function completedAudit(data) {
  return service['api-web']({
    url: '/data/supplyAuthorization/completedAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    url: '/public/activiti/supplyAuthorization/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// /**
//  * @param {(string|number)} ids
//  * @description 结束流程（结束审核流）
//  */
// export function withdraw(ids) {
//   return service['api-web']({
//     url: '/data/supplyAuthorization/withdraw',
//     method: 'post',
//     data: { ids },
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }

/**
 * 判断绑定关系是否存在
 * @param {{productCode:string,supplierCode:string}} params
 */
export function isExistSupply(params) {
  return service['api-web']({
    url: '/data/supplyAuthorization/get/isExistSupply',
    method: 'get',
    params,
    tipTypes: 'none'
  })
}

/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/supplyAuthorization/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 授权导入的模板下载
 */
export function downloadExcel() {
  return service['api-web']({
    url: '/data/supplyAuthorization/downloadExcel',
    method: 'post',
    responseType: 'blob',
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 下推
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/data/supplyAuthorization/pushDown',
    method: 'post',
    data
  })
}

/** 工作流程图 */
export function getInstanceHis(params) {
  return service['api-activiti']({
    url: '/sys/process/instance/getInstanceHis',
    method: 'get',
    params
  })
}

/**
 * @description 撤销
 */
export function withdraw(data) {
  return service['api-web']({
    url: '/data/supplyAuthorization/withdraw',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
