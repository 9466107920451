<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
console.log(
  '%c AnSaiRui SmartData',
  'font-weight: bold; font-size: 40px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113); margin-bottom: 12px; padding: 5%'
)
import { mapGetters } from 'vuex'
import { get } from './storage'
import { getToken } from '@/utils/auth'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['pmNames'])
  },
  watch: {
    /**
     * @description 前置获取省市区地址信息数据
     *
     * 地址信息数据来源 `scm` 服务下公共接口 @see /public/order/getAllGeographicCoordinates
     *
     * 地址信息数据的接口目前设计是一次性返回全量，数据较大，响应时长较长，有较高的延迟，（可能受到目前的带宽限制），影响到了用户体验
     *
     * 因此，前端相对更前置数据请求，不再是 `RegionCascader` 组件 created 生命勾子执行时即时请求，从而**将用户对数据加载过慢的感知降到最小**
     * @see /src/components/RegionCascader/index.vue
     *
     * 当用户根据角色获取权限之后（用户登录后、待办跳转单据详情页前...），
     * @see /src/store/modules/permission.js
     *
     * 如果用户权限中包含了 `RegionCascader` 组件所在页面的访问权限，随即在此前置请求地址信息数据
     */
    //  new:新开标签也会导致重复请求地址信息，在组件中已经请求，此处忽略
    // pmNames: {
    //   immediate: false,
    //   handler(pms) {
    //     if (
    //       [
    //         'web:sell.order', // 销售订单 & 合同订单
    //         'scm:scm.order.orderInfo' // 订单管理
    //       ].some((pm) => pms.includes(pm))
    //     ) {
    //       this.$store.dispatch('pcd/fetchData')
    //     }
    //   }
    // }
  },

  mounted() {
    document.addEventListener('visibilitychange', this.handleAccountChange)
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleAccountChange)
  },
  created() {
    // 刷新浏览器时，更新权限
    const token = getToken()
    if (token) {
      this.$store.dispatch('permission/init')
    }
  },
  methods: {
    handleAccountChange() {
      if (document.visibilityState !== 'visible') {
        return
      }

      const nameOfLocal = get('local', 'ASR_USER_NAME')

      if (this.$route.path.includes('/login') || nameOfLocal === '') {
        return
      }

      const nameOfSession = get('session', 'ASR-SCM-SYS-AUTH-NAME')
      if (nameOfSession !== nameOfLocal) {
        const key = btoa('notRedirectingToHome')

        // 当其他标签页退出时，localStorage同步更新，无需再清除，而sessionStorage在登录后会覆盖原有的
        this.$router.replace({
          path: '/login',
          query: {
            [key]: 1 // 如果notRedirectingToHome为false，则跳转至首页，否则允许跳转至登录页
          }
        })
      }
    }
  }
}
</script>
