<template>
  <el-table
    ref="base-table"
    :key="uuTableKey"
    v-loading="loading"
    :class="[
      'base-table',
      'base-el-table',
      columnWidthResizable
        ? [
            'column-width-resizable',
            $attrs?.border !== true ? 'hide-border' : ''
          ]
        : '',
      isChildrenSelectHidden ? 'table-children-select' : '',
      ...externalClasses
    ]"
    :size="getSize"
    :data="data"
    :row-key="rowKey"
    :height="tableHeight"
    :max-height="maxHeight || tableHeight"
    :highlight-current-row="highlightCurrentRow"
    :style="extraStyle"
    :header-cell-style="{ 'text-align': 'left' }"
    :cell-style="{ 'text-align': 'left' }"
    :header-cell-class-name="headerClassName"
    v-bind="proxyProps"
    :load="loadChildren"
    :row-style="rowStyle"
    @selection-change="_selectionChange"
    @header-dragend="onHeaderDragend"
    v-on="$listeners"
    @cell-mouse-enter="handleRowMouseEnter"
    @cell-mouse-leave="handleRowMouseLeave"
    @cell-contextmenu="handleCellContextmenu"
    @sort-change="onSortChange"
  >
    <!-- 表格树折叠 -->
    <slot name="expand" />
    <!-- 表格序号 -->
    <el-table-column
      v-if="hasIndex"
      type="index"
      label-class-name="ignore-elements"
      prop="index"
      label="序号"
      align="center"
      :width="
        tableHeader.find((item) => item.prop === 'index')?.width || 'auto'
      "
      show-overflow-tooltip
      :min-width="30"
      fixed="left"
      :render-header="adaptationWidth"
    />

    <!-- 表格复选框 -->
    <slot name="selection" />
    <template v-for="(item, index) in tableHeader">
      <el-table-column
        v-if="item.type"
        :key="typeof item.id !== 'undefined' ? item.id + '1' : index + '1'"
        :label="item.label"
        :prop="item.prop || item.type"
        :type="item.type"
        fixed="left"
        show-overflow-tooltip
        :reserve-selection="reserve"
        :min-width="30"
      />
      <!-- <el-table-column
        v-else-if="item.filters"
        :key="typeof item.id !== 'undefined' ? item.id : index"
        :type="item.type"
        :label="item.label"
        :prop="item.prop || item.type"
        :fixed="item.fixed"
        :width="item.width || 'auto'"
        :align="item.align || 'center'"
        :min-width="120"
        :max-width="item.maxWidth"
        :sortable="typeof item.sortable === 'boolean' ? item.sortable : false"
        :show-overflow-tooltip="
          typeof item.showOverflowTooltip === 'boolean'
            ? item.showOverflowTooltip
            : false
        "
        :filters="item.filters"
        :filter-method="filterHandler"
        filter-placement="bottom"
      >
        <template #default="{ row }">
          <slot
            v-if="
              item.isComp &&
              (typeof row[item.prop] !== 'undefined' || row[item.prop] !== null)
            "
            :row="
              Object.assign(row, {
                [item.prop]:
                  typeof item.formatter === 'function'
                    ? item.formatter(row)
                    : row[item.prop]
              })
            "
            :name="item.prop"
          />
          <span
            v-else-if="
              (typeof row[item.prop] === 'string' &&
                row[item.prop] !== '' &&
                row[item.prop].trim()) ||
              typeof row[item.prop] === 'number'
            "
            :class="[item.prop === 'index' ? 'customer-index' : '']"
          >
            {{
              typeof item.formatter === 'function'
                ? item.formatter(row)
                : row[item.prop]
            }}
          </span>
          <span v-else class="table-na-placeholder">
            {{
              typeof item.formatter === 'function'
                ? item.formatter(row)
                : showDash
                ? '⏤'
                : ''
            }}
          </span>
        </template>
      </el-table-column> -->
      <el-table-column
        v-else
        :key="typeof item.id !== 'undefined' ? item.id + '2' : index + '2'"
        :type="item.type"
        :label="item.label"
        :prop="item.prop || item.type"
        :fixed="item.fixed"
        :width="item.width || 'auto'"
        :align="item.align || 'center'"
        :min-width="30"
        :max-width="item.maxWidth"
        :sortable="typeof item.sortable === 'boolean' ? item.sortable : false"
        :show-overflow-tooltip="
          typeof item.showOverflowTooltip === 'boolean'
            ? item.showOverflowTooltip
            : true
        "
        :render-header="
          (h, { column, $index }) =>
            adaptationWidth(h, { column, $index }, item)
        "
        v-bind="
          item.filters
            ? {
                filters: item.filters,
                filterMethod: filterHandler,
                filterPlacement: 'bottom'
              }
            : {}
        "
      >
        <template #default="{ row }">
          <svg-icon
            v-if="item.autoCopy && hoveredRow === row && row[item.prop]"
            icon-class="i-copy-hollow"
            style="cursor: pointer"
            class="copySvg"
            @click.stop="goHandleCopy(row[item.prop])"
          />
          <slot
            v-if="
              item.isComp &&
              (typeof row[item.prop] !== 'undefined' || row[item.prop] !== null)
            "
            :row="
              Object.assign(row, {
                [item.prop]:
                  typeof item.formatter === 'function'
                    ? item.formatter(row)
                    : row[item.prop]
              })
            "
            :name="item.prop"
          />
          <span
            v-else-if="
              (typeof row[item.prop] === 'string' &&
                row[item.prop] !== '' &&
                row[item.prop].trim()) ||
              typeof row[item.prop] === 'number'
            "
            :class="[item.prop === 'index' ? 'customer-index' : '']"
          >
            {{
              typeof item.formatter === 'function'
                ? item.formatter(row)
                : row[item.prop]
            }}
          </span>
          <span v-else class="table-na-placeholder">
            {{
              typeof item.formatter === 'function'
                ? item.formatter(row)
                : showDash
                ? '⏤'
                : ''
            }}
          </span>
        </template>
      </el-table-column>
    </template>
    <!-- 表格操作列 -->
    <slot name="operation" />
  </el-table>
</template>

<script>
import { uuid } from '@/utils'
import { get as getCookie } from '@/utils/cookies'
import { isArray, isString } from '@/utils/validate'
import { createUniqueString } from '@/utils/string'
import { JSONStringify, JSONParse } from '@/utils/jsonModification'
import { debounce } from '@/utils'
import listHeaderCache from '@/storage/local/listHeader'
import Sortable from 'sortablejs'
import store from '@/store'
import { getUserTableSort, setUserTableSort } from '@/service/tableDrag'
import { cloneDeep } from 'lodash'
import { handleCopy } from '@/utils/clipboard'
export default {
  /**
   * based on el-table
   */
  name: 'BaseTable',

  props: {
    /**
     * @description page scope
     */
    scope: {
      type: Object,
      default: () => ({})
    },

    tableKey: {
      type: String,
      required: true
    },

    renderRowColor: {
      type: Boolean,
      default: false
    },

    header: {
      type: Array,
      default: () => [],
      required: true
    },

    data: {
      type: Array,
      default: () => [],
      required: true
    },
    size: {
      type: String,
      default: ''
    },
    columnWidthResizable: {
      type: Boolean,
      default: true
    },
    handleCellContextmenu: {
      type: Function,
      default: () => ({})
    },
    externalClass: {
      type: [Array, String],
      default: ''
    },
    extraStyle: {
      type: [Object, String],
      default: () => ({})
    },
    width: {
      type: String,
      default: 'width:100%'
    },
    height: {
      type: [String, Number],
      default: undefined
    },

    // TODO 自动计算最大高度
    maxHeight: {
      type: [String, Number],
      default: undefined
    },

    dragColumnKey: {
      type: [String, Number],
      default: undefined
    },
    showIndex: {
      type: [Boolean, String],
      default: true,
      validator: (v) => typeof v === 'boolean' || v === 'force'
    },
    rowKey: {
      type: Function,
      default: (row) => row?.id ?? uuid()
    },
    /**
     * 是否是多个弹窗 此处为不影响其他逻辑仅处理两个弹窗.
     *@description 由于拖拽时获取元素为.el-table__header-wrapper tr,
      当页面有多个table需要拖拽时,会导致识别失败,暂用multipleTableDrop区分处理.
     */
    multipleTableDrop: {
      type: Boolean,
      default: false
    },
    /**
     * @deprecated
     */
    selectionChange: {
      type: Function,
      default: () => {}
    },
    highlightCurrentRow: {
      type: Boolean,
      default: true
    },
    headerCellClassName: {
      type: [Function, String],
      default: 'hover-highlight'
    },
    reserve: {
      type: Boolean,
      default: false
    },
    // 是否可以拖拽列
    isDrop: {
      type: Boolean,
      default: true
    },

    showDash: {
      type: Boolean,
      default: true
    },
    /**
     * 是否展示子节点头部复选框
     */
    isChildrenSelectHidden: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      $table: null,
      selection: [],
      treeTableMap: new Map(),
      loading: false,
      columns: [],
      hoveredRow: null
    }
  },

  computed: {
    getSize() {
      return (
        this.size || this.$store.getters.size || getCookie('size') || 'mini'
      )
    },
    uuTableKey() {
      return this.tablekey
        ? `base-table:${createUniqueString()}`
        : `base-table:${this.tableKey}:${createUniqueString()}`
    },
    proxyProps() {
      /**
       * element ui 的列宽宽度拖拽调整 依赖 border true,
       * 本项目 表格 UI 设计 去掉了 表格的 border, 那么实现的方式， border true + css 隐藏 bordr 的样式
       * 这样一来，列宽宽度拖拽调整与 border 样式实际上产生了“冲突”
       */
      const props = this.columnWidthResizable
        ? { ...this.$attrs, border: true }
        : this.$attrs

      // eslint-disable-next-line no-unused-vars
      const { load = null, ...rest } = props
      return rest
    },
    hasIndex() {
      try {
        return (
          this.showIndex === 'force' ||
          (this.showIndex === true &&
            typeof this.$slots.selection === 'undefined')
        )
      } catch (e) {
        console.error(e)
      }
      return true
    },
    externalClasses() {
      return isString(this.externalClass) && this.externalClass
        ? [this.externalClass]
        : isArray(this.externalClass)
        ? this.externalClass.filter(
            (className) => isString(className) && className
          )
        : []
    },
    /**
     * @returns {(string|number)=}
     */
    tableHeight() {
      return this.height || this.scope?.tableHeight || undefined
    },

    tableHeader() {
      const { key, setup } = listHeaderCache
      const result = setup(key.call(this, this.tableKey), this.columns)
      return Array.isArray(result) ? result : []
    }
  },
  watch: {
    data() {
      // table scroll reset
      if (this.tableHeight) this.$table.bodyWrapper.scrollTop = 0
      this.layout()
    },
    tableHeight: {
      immediate: true,
      handler: 'layout'
    },
    header: {
      handler(newValue) {
        this.columns = newValue
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {
    this.$table = this.$refs['base-table']
    if (this.isDrop) {
      this.columnDrop()
    }
    getUserTableSort(
      store.state.user.id,
      'erp',
      store.state.user.jobId,
      this.dragColumnKey
    ).then(
      (res) => {
        if (res.code === 200 && res.info && res.info.columns) {
          var serverCol = JSONParse(res.info.columns)
          var reorderedArray = serverCol.map((item2) => {
            const matchedItem = this.header.find((item1) => {
              const label1 = item1.label || item1.type
              const prop1 = item1.prop || item1.props

              const label2 = item2.label || item2.type
              const prop2 = item2.prop || item2.props

              return label1 === label2 && prop1 === prop2
            })

            return matchedItem ?? {}
          })
          if (reorderedArray.length > 0) {
            this.columns = reorderedArray
          } else {
            this.columns = this.header
          }
        }
      },
      () => {
        this.columns = this.header
      }
    )
  },
  methods: {
    headerClassName(row) {
      if (
        row.column.property === 'selection' ||
        row.column.property === 'index'
      ) {
        return `${this.headerCellClassName} ignore-elements`
      } else {
        return `${this.headerCellClassName} able-elements`
      }
    },
    columnDrop() {
      // 获取全部需要拖拽的table,此处仅处理数量为两个的情况
      let wrapperTr
      const wrapperTrAll = document.querySelectorAll(
        '.el-table__header-wrapper tr'
      )
      // wrapperTr.style.cursor = 'move'
      wrapperTr = wrapperTrAll[0]
      if (this.multipleTableDrop) {
        wrapperTr = wrapperTrAll[1]
      }
      this.sortable = Sortable.create(wrapperTr, {
        animation: 500,
        handle: '.table-head',
        filter: '.ignore-elements',
        draggable: '.able-elements',
        direction: 'vertical',
        ghostClass: 'sortable-ghost',
        chosenClass: 'sortable-chosen',
        dragClass: 'sortable-drag',
        forceFallback: true,
        onMove(e) {
          return e.related.className.indexOf('ignore-elements') === -1
        },
        onEnd: (evt) => {
          let index = 0
          if (this.showIndex) index++

          if (this.$scopedSlots.selection) index++
          const selectionItem = this.columns.filter(
            (item) => item?.type === 'index'
          )

          if (selectionItem.length > 0 && this.showIndex) {
            index--
          }
          console.log(index, 'index')
          const newItem = this.columns[evt.newIndex - index]
          const oldItem = this.columns[evt.oldIndex - index]

          if (!newItem || !oldItem) {
            return
          }

          if (
            newItem?.['prop'] === 'index' ||
            newItem?.['type'] === 'selection' ||
            oldItem?.['prop'] === 'index' ||
            oldItem?.['type'] === 'selection'
          ) {
            return
          }

          const columnCopy = cloneDeep(this.columns)

          columnCopy.splice(
            evt.newIndex - index,
            0,
            columnCopy.splice(evt.oldIndex - index, 1)[0]
          )

          this.columns = []

          this.$nextTick(() => {
            this.columns = columnCopy
            if (!this.dragColumnKey) {
              console.error(
                '【DragColumnKey not passed】表格拖拽需要dragColumnKey，当前未找到'
              )
            }
            setUserTableSort({
              userId: store.state.user.id,
              projectName: 'erp',
              systemType: store.state.user.jobId,
              tableId: this.dragColumnKey,
              columns: JSONStringify(this.columns)
            })
            this.handleDragEnd()
          })
        }
      })
    },

    rowStyle({ row }) {
      if (row.color && this.renderRowColor) {
        return {
          backgroundColor: row.color
        }
      }
    },
    layout: debounce(function () {
      this.$nextTick(() => {
        if (typeof this.$table?.doLayout === 'function') {
          console.log('【BaseTable】re-doLayout')
          this.$table.doLayout()
        } else console.warn('【BaseTable】quit doLayout')
      })
    }, 400),
    /**
     * @description 获取勾选项数据
     * @param {array} e 勾选项的集合
     */
    _selectionChange(e) {
      this.selection = e
      this.$emit('selection-change', e)
      this.selectionChange(e)
    },
    /**
     * @description 获取el-table 勾选框数据
     */
    getSelectionList() {
      return this.selection
    },
    onHeaderDragend(newWidth, oldWidth, column, event) {
      // 限制最小宽度为1个中文字符和省略号...
      if (newWidth < 43) {
        newWidth = 43
      }

      column.width = newWidth
      column.realWidth = newWidth

      this.$emit('header-dragend', { newWidth, oldWidth, column, event })

      const { key, updateColumnWidth } = listHeaderCache
      const _key = key.call(this, this.tableKey)
      debounce(function () {
        updateColumnWidth(_key, column, newWidth)
      }, 400)()
    },

    handleDragEnd() {
      this.$emit('handleDrag-end')
    },

    /**
     * @description 设置每一列的最小宽度
     * @param {function} h
     * @param {column | $index} param
     */
    adaptationWidth(h, { column, $index }, item) {
      const labelLength = column.label.length
      const fontSize = 24
      column.minWidth = labelLength * fontSize
      // 是否显示头部提示框，需配合tooltipContent
      if (item.isShowHeaderTooltip) {
        return item.tooltipContent()
      } else {
        return h('span', { class: 'table-head', style: { width: '100%' } }, [
          column.label
        ])
      }
    },

    async loadChildren(tree, treeNode, resolve) {
      if (typeof this.$attrs?.load !== 'function') {
        return void console.error('Losed "load" func')
      }
      this.treeTableMap.set(tree.id, { tree, treeNode, resolve })
      resolve(await this.$attrs.load(tree, treeNode))
    },
    async reloadChildren(parentRowId) {
      if (typeof this.$attrs?.load !== 'function') {
        return void console.error('Losed "load" func')
      }
      try {
        this.loading = true
        const { tree, treeNode, resolve } =
          this.treeTableMap.get(parentRowId) ?? {}

        if (!tree || !treeNode || !resolve) {
          return void console.error('"reloadChildren" error')
        }

        await this.loadChildren(tree, treeNode, resolve)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    } /* 鼠标经过*/,
    handleRowMouseEnter(row, column, cell, event) {
      this.hoveredRow = row
    },
    /* 鼠标离开*/
    handleRowMouseLeave() {
      this.hoveredRow = null
    },
    /* 复制*/
    goHandleCopy(txt) {
      handleCopy(txt)
    },
    /**
     * 过滤函数
     */
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },

    /**
     * 排序函数
     */
    onSortChange(column) {
      this.$emit('sort-change', column)
    },
    sort(prop, order) {
      this.$refs['base-table'].sort(prop, order)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';

.base-table {
  transition: height 32ms;
}
.base-table :deep(.el-table__body-wrapper, .el-table__fixed-body-wrapper) {
  @include scrollbar;
  transition: height 128ms;
}
.base-table :deep(.el-table__fixed-body-wrapper .el-table__body) {
  padding-bottom: 7px;
}
:deep(.table-na-placeholder) {
  color: #ccc;
}
.table-children-select {
  :deep(.el-table__row--level-1) {
    .table-na-placeholder,
    .customer-index,
    .el-table-column--selection > div {
      visibility: hidden;
    }
  }
}

:deep(.red-font) {
  color: red !important;
  .el-link {
    color: red;
  }
}

/* 表头行悬停高亮 */
:deep(.el-table__header) {
  th.el-table__cell.hover-highlight:hover {
    background-color: #e6f7ff !important;
  }
}
.column-width-resizable.hide-border.el-table--border {
  border: 0 none transparent;

  :deep(.el-table__cell) {
    border-right: 0 none transparent;
  }
}
.column-width-resizable.hide-border.el-table--group::before,
.column-width-resizable.hide-border.el-table--group::after,
.column-width-resizable.hide-border.el-table--border::before,
.column-width-resizable.hide-border.el-table--border::after,
.column-width-resizable.hide-border :deep(.el-table__fixed::before),
.column-width-resizable.hide-border :deep(.el-table__fixed::after),
.column-width-resizable.hide-border :deep(.el-table__fixed-right::before),
.column-width-resizable.hide-border :deep(.el-table__fixed-right::after) {
  width: 0;
  height: 0;
  background-color: transparent;
}

:deep(.table-head) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.column-width-resizable.hide-border.el-table--border :deep(.el-table__cell) {
  text-align: left;
}

/* 滚动条顶开行高导致列没有对齐 设置后滚动条对行高无影响 bug:6480去除 */
// :deep(.el-table__body-wrapper) {
//     overflow-x: overlay;
//     /* 滚动条的高度会占用表格内部高度，padding-bottom值与滚动条高度同步 */
//     padding-bottom: 10px;
//   }
.copySvg {
  margin-right: 2px;
  cursor: pointer;
}
.custom-filter-trigger {
  .svg-icon {
    cursor: pointer;
  }
}
:deep(.el-table__column-filter-trigger i) {
  color: #000;
  font-size: 15px;
  transform: unset;
  margin-left: 3px;
}
</style>
